const promisedTimer = new Promise((resolve) => {
  const timer = document.querySelector("[data-timer]");

  if (timer) {
    const daysSlot = timer.querySelector('[data-timer-slot="d"]');
    const hoursSlot = timer.querySelector('[data-timer-slot="h"]');
    const minutesSlot = timer.querySelector('[data-timer-slot="m"]');
    const secondsSlot = timer.querySelector('[data-timer-slot="s"]');
    
    if (daysSlot && hoursSlot && minutesSlot && secondsSlot) {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + Number(daysSlot.textContent));
      endDate.setHours(endDate.getHours() + Number(hoursSlot.textContent));
      endDate.setMinutes(endDate.getMinutes() + Number(minutesSlot.textContent));
      endDate.setSeconds(endDate.getSeconds() + Number(secondsSlot.textContent));
      
      const updateTimer = () => {
        const now = Date.now();
        const timeLeft = endDate - now;
    
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
        const seconds = Math.floor((timeLeft / 1000) % 60);
      
        daysSlot.textContent = `${days < 10 ? '0' : ''}${days}`;
        hoursSlot.textContent = `${hours < 10 ? '0' : ''}${hours}`;
        minutesSlot.textContent = `${minutes < 10 ? '0' : ''}${minutes}`;
        secondsSlot.textContent = `${seconds < 10 ? '0' : ''}${seconds}`;
        
        requestAnimationFrame(updateTimer);
      };

      requestAnimationFrame(updateTimer);
      resolve();
    } 
  }
});

document.addEventListener("DOMContentLoaded", () => {
  promisedTimer.then();
});